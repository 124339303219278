import React, { ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

export type StackedLayoutProps = {
  children: ReactNode;
};

/**
 * ## Stacked Layout is a simple wrapper providing vertical content padding.
 *
 * Use Stacked Layout to combine stackable layout elements together. Examples
 * include Navbar, Content Container, etc.
 *
 * Of notable importance is the inclusion of:
 * - `py`: `8`
 * - `mt`: `16`
 *
 */
const StackedLayout = ({ children }: StackedLayoutProps) => (
  <Box py="8" mt="16">
    {children}
  </Box>
);

export default StackedLayout;
