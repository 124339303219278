import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import Sentry from '@/src/lib/initSentry';

const initLogrocket = () => {
  if (typeof window !== 'undefined') {
    switch (process.env.REVISION_ENVIRONMENT) {
      case 'mttr-dev':
      case 'preview':
        LogRocket.init('matternet/apps-preview');
        setupLogRocketReact(LogRocket);
        break;
      case 'staging':
        // TODO: Can we get LogRocket to reset this App ID or rename to 'apps-staging'?
        LogRocket.init('matternet/apps-staging-npqab');
        setupLogRocketReact(LogRocket);
        break;
      case 'edge':
        LogRocket.init('matternet/apps-edge');
        setupLogRocketReact(LogRocket);
        break;
      case 'tc':
        LogRocket.init('matternet/apps-tc');
        setupLogRocketReact(LogRocket);
        break;
      case 'production':
        LogRocket.init('matternet/apps-production');
        setupLogRocketReact(LogRocket);
        break;
      default:
        Sentry.captureMessage(
          `INFO: ${process.env.REVISION_ENVIRONMENT} not being setup to use LogRocket.`,
        );
        break;
    }

    LogRocket.getSessionURL((sessionURL) => {
      Sentry.configureScope((scope) => {
        scope.setExtra('sessionURL', sessionURL);
      });
    });
  }
};

export default initLogrocket;
